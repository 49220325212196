import React from "react";
import "./Bienvenidos.css";

const Bienvenidos = () => {
  return (
    <section id="bienvenidos-section">
      <div className="bienvenidos-section-content">
        <div className="left-content">
          <h2>MOVIHER CONTRATISTAS GENERALES S.A.C.</h2>
          <p>SOMOS UNA EMPRESA PERUANA DEDICADA A LA ELABORACIÓN Y CONSTRUCCIÓN DE PROYECTOS DE VIVIENDA, INDUSTRIA Y COMERCIO CON MÁS DE VIENTE AÑOS DE EXPERIENCIA EN EL MERCADO. ESTAMOS ORGULLOSOS DE NUESTROS LOGROS Y CRECIMIENTO, Y SABEMOS QUE ESTO SE DEBE A LA SATISFACCIÓN TOTAL DE NUESTROS CLIENTES.</p>
        </div>
      </div>
    </section>
  );
};

export default Bienvenidos;
